.work-experience-container {
    max-width: 900px;
    margin: 60px auto 0;
    padding: 40px;
    background-color: #f9f9f9; /* Light background */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
  }
  
  .experience-item {
    margin-bottom: 20px;
    border: 1px solid #ddd; /* Light border */
    border-radius: 8px; /* Rounded corners */
    padding: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
    background-color: #fff; /* White background for items */
  }
  
  .experience-item:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
    background-color: #ecf0f1; /* Light gray background on hover */
  }
  
  .experience-item h3 {
    color: #2c3e50; /* Darker title color */
    margin: 0;
    transition: color 0.3s ease; /* Transition for title color */
  }
  
  .experience-item h4 {
    color: #34495e; /* Medium title color */
    margin: 5px 0;
    transition: color 0.3s ease; /* Transition for company color */
  }
  
  .experience-duration {
    font-style: italic;
    color: #7f8c8d; /* Gray color for duration */
    transition: color 0.3s ease; /* Transition for duration color */
  }
  
  .experience-item:hover h3 {
    color: #2980b9; /* Change title color on hover */
  }
  
  .experience-item:hover h4 {
    color: #3498db; /* Change company color on hover */
  }
  
  .experience-item:hover .experience-duration {
    color: #95a5a6; /* Change duration color on hover */
  }
  