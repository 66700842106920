#contact {
  text-align: center;
  padding: 20px;
}

.contact-buttons {
  margin-top: 15px;
}

.contact-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.linkedin {
  background-color: #0077b5; /* LinkedIn color */
}

.gmail {
  background-color: #db4437; /* Gmail color */
}

.contact-button:hover {
  opacity: 0.8; /* Add a hover effect */
}
