body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header {
  background: #282c34;
  color: white;
  padding: 20px;
  text-align: center;
}

section {
  padding: 20px;
}

footer {
  text-align: center;
  padding: 10px;
  background: #282c34;
  color: white;
  position: absolute;
  width: 100%;
  bottom: 0;
}
